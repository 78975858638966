import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'

import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import BusinessIcon from '@material-ui/icons/Business';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';

import { FptList, FptListItem } from '../../utils/FptList';

const FptTeilnehmerListe = (props) => {

    const [showFirmen, setShowFirmen] = useState(true);
    const [showJournalisten, setShowJournalisten] = useState(true);
    const [suchFilter, setSuchFilter] = useState('');



    const history = useHistory();

    const einladen = (person) => {
        history.push(`/${props.idFpt}/einladen/${person.idFptProfilPerson}/${props.idFpt}/${!person.firmenname}/false`);
    }

    const zumProfil = (person) => {
        history.push(`/${props.idFpt}/profil/${person.idFptProfilPerson}`);
    }

    const onSuchFilterChange = (e) => {
        const _suchfilter = e.target.value;
        setSuchFilter(_suchfilter)
    }

    return (
        <React.Fragment>
            <TextField
                autoFocus={false}
                fullWidth
                value={suchFilter}
                label="Suche Redaktion/Firma/Person"
                variant="outlined"
                onChange={onSuchFilterChange}
            />

            <FormGroup row>
                <FormControlLabel
                    control={<Checkbox checked={showFirmen} onChange={() => setShowFirmen(!showFirmen)} />}
                    label={
                        <span>
                            <BusinessIcon style={{ marginRight: '8px' }} />
                            Firmenteilnehmer
                        </span>
                    }
                />

                <FormControlLabel
                    control={<Checkbox checked={showJournalisten} onChange={() => setShowJournalisten(!showJournalisten)} />}
                    label={
                        <span>
                            <ImportContactsIcon style={{ marginRight: '8px' }} />
                            Journalisten
                        </span>
                    }
                />
            </FormGroup>


            <FptList style={{ width: '100%', marginTop: '12px' }}>

                {props.personen.length > 0 && props.personen
                    .sort((a, b) => (a.nachname < b.nachname ? -1 : 1))
                    .filter(person => {
                        if (person.idFptTeilnehmerFirma > 0) {
                            return showFirmen;
                        }
                        if (person.idFptTeilnehmerFirma === 0) {
                            return showJournalisten
                        }
                    })
                    .filter(person => suchFilter ?
                        person.firmenname && person.firmenname.toUpperCase().includes(suchFilter.toUpperCase())
                        ||
                        person.firmennamen && person.firmennamen.join().toUpperCase().includes(suchFilter.toUpperCase())
                        ||
                        (person.nachname.toUpperCase().includes(suchFilter.toUpperCase()))
                        : true)
                    .map(person =>

                        <FptListItem
                            key={person.idFptProfilPerson.toString() + person.idFptTeilnehmerFirma.toString()}
                            primaryText={(person.titel ? person.titel + " " : "") + person.vorname + " " + person.nachname}
                            secondaryText={
                                <div>
                                    {person.funktion && person.funktion}
                                    {person.funktion && <br />}
                                    {person.firmenname ? person.firmenname : person.firmennamen.join(', ')}
                                </div>
                            }
                            avatar={person.bild ? person.bild : 'dummy'}
                            avatarBadge={person.idFptTeilnehmerFirma ? 'f' : 'j'}
                            tooltipRechts={person.nurOnline && "online-Teilnehmer"}
                            chipRechts={person.nurOnline && "online-Teilnehmer"}
                            onClick={() => zumProfil(person)}
                        />
                    )

                        }

            </FptList>
        </React.Fragment>

    )
}

export default connect()(FptTeilnehmerListe);


/*

                        <Tooltip
                            key={person.idFptProfilPerson.toString() + person.idFptTeilnehmerFirma.toString()}
                            title={"Profil von " + (person.titel ? person.titel + " " : "") + person.vorname + " " + person.nachname}
                            placement='bottom'
                            arrow
                        >


 <ListItem
                                button
                                onClick={() => zumProfil(person)}
                            >
                                <ListItemIcon>
                                    <Badge badgeContent={person.idFptTeilnehmerFirma ? <BusinessIcon fontSize="small" /> : <ImportContactsIcon fontSize="small" />} >
                                        <Avatar src={person.bild && (avatarUri + person.bild)} />
                                    </Badge>
                                </ListItemIcon>
                                <ListItemText
                                    primary={(person.titel ? person.titel + " " : "") + person.vorname + " " + person.nachname}
                                    secondary={
                                        <div>
                                            {person.funktion && person.funktion}
                                            {person.funktion && <br />}
                                            {person.firmenname ? person.firmenname : person.firmennamen.join(', ')}
                                        </div>
                                    }
                                />

                                <ListItemSecondaryAction>
                                    {person.nurOnline && <Chip size="small" label="online-Teilnehmer" color="secondary" />}

                                    {config[props.idFpt].meetingsOpen &&
                                        <Tooltip title="Zum Gespräch einladen">
                                            <IconButton color="secondary" component="span" onClick={() => einladen(person)}>
                                                <PeopleIcon />
                                            </IconButton>
                                        </Tooltip>
                                    }
                                </ListItemSecondaryAction>

                            </ListItem>
</Tooltip>
                            */