import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'

import Alert from '@material-ui/lab/Alert';
import FptForceAnmeldung from '../../utils/FptForceAnmeldung';
import FptHeader from '../../utils/FptHeader';
import FptTeilnehmerListe from '../generic/FptTeilnehmerListe'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography';

import { startReadAllePersonen } from '../../../actions/fpt';


const idFpt = 'fpt032';

const PageTeilnehmerFpt032 = (props) => {

	useEffect(() => {
		loadTeilnehmer();
	}, []);

	const loadTeilnehmer = () => {
		props.dispatch(startReadAllePersonen(idFpt));
	}


	return (
		<React.Fragment>
			<FptHeader title="Fachpressetage 2021" />

			<FptForceAnmeldung idFpt={idFpt} fptTitel="Fachpressetage 2021">

				<Typography variant="h4" gutterBottom>
					Teilnehmer
				</Typography>
				
				<Grid container spacing={2}>
					<Grid item xs={12} md={8} lg={6}>

						<Alert severity="info" style={{marginBottom:'24px'}}>Klicken Sie auf eine Person, um sie zu einem Gespräch einzuladen.</Alert>

						<FptTeilnehmerListe personen={props.allePersonen} idFpt={idFpt}/>

					</Grid>
				</Grid>

			</FptForceAnmeldung>

		</React.Fragment>
	);

}


const mapStateToProps = state => ({
	allePersonen: state.fpt.fpt032.allePersonen
});

export default connect(mapStateToProps)(PageTeilnehmerFpt032);
