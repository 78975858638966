// Das könnte man evtl. auch über einen Service holen, der er aus einem JSON-File 
// lädt. Dann wären wir sehr dynmaisch und könnten zur Laufzeit freischalten!

export const config = {
    currentFpt: 'fpt036',
    fpt032: {
        live: false,
        download: true
    },
    fpt033: {
        live: false,
        download: true,
        meetingsOpen: false,
        votingOpen: false,
        anmeldungJ: true,
        anwesenheitenAnzeigen: true,
        einladungGespraech: false,
        tag1: new Date('2022-03-23'), 
        tag2: new Date('2022-03-24')
    },
    fpt034: {
        live: false,
        download: true,
        meetingsOpen: false,
        votingOpen: false,
        ergebnisAnzeigen: true,
        anmeldungJ: true,
        anwesenheitenAnzeigen: true,
        anwesenheitenEintragen: false,
        einladungGespraech: false,
        tag1: new Date('2023-02-07'), 
        tag2: new Date('2023-02-08')
    },
    fpt035: {
        live: false,
        download: true,
        meetingsOpen: false,
        votingOpen: false,
        wahlEnde: '2023-11-16',
        ergebnisAnzeigen: true,
        anmeldungJ: true,
        anwesenheitenAnzeigen: true,
        anwesenheitenEintragen: false,
        einladungGespraech: false,
        tag1: new Date('2024-02-06'), 
        tag2: new Date('2024-02-07'),
        termin: '6. & 7. Februar'
    },
    fpt036: {
        live: false,
        download: false,
        meetingsOpen: false,
        votingOpen: false,
        wahlEnde: '2024-11-16',
        ergebnisAnzeigen: false,
        anmeldungJ: true,
        anwesenheitenAnzeigen: false,
        anwesenheitenEintragen: true,
        einladungGespraech: false,
        tag1: new Date('2025-02-04'), 
        tag2: new Date('2025-02-05'),
        termin: '4. & 5. Februar'
    },
}