

export const dateToString = (date) => {
    const dateString =
        date.getFullYear() +
        '-' +
        ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) +
        '-' +
        ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate()));

    return dateString + 'T00:00:00';
}

export const dateTimeToString = (date) => {
    const dateString =
        date.getFullYear() +
        '-' +
        ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) +
        '-' +
        ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) +
        'T' +
        ((date.getHours() > 9) ? date.getHours() : ('0' + date.getHours())) +
        ':' +
        ((date.getMinutes() > 9) ? date.getMinutes() : ('0' + date.getMinutes())) +
        ':' +
        ((date.getSeconds() > 9) ? date.getSeconds() : ('0' + date.getSeconds()));

    return dateString;
}

const getYesterday = () => {
    return getDaysAgo(1);
}

const getDaysAgo = (days) => {
    var d = new Date();
    d.setDate(d.getDate() - days);
    return d;
}

export const getNowJsonString = () => { return dateTimeToString(new Date()); }
export const todayJsonString = dateToString(new Date());
export const yesterdayJsonString = dateToString(getYesterday());
export const tomorrowJsonString = dateToString(getDaysAgo(-1));

export const getJsonDateMinusDays = (days) => {
    return dateToString(getDaysAgo(days));
}

export const getJsonDateMinusYears = (years) => {
    let datum = new Date();
    datum.setFullYear(datum.getFullYear() - years);
    return dateToString(datum);
}

export const jsonDateToDisplay = (jsonDate) => {
    var date = new Date(jsonDate)
    return ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + "." +
        ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + "." +
        date.getFullYear();
}

export const jsonDateToDisplayShort = (jsonDate) => {
    var date = new Date(jsonDate)
    return dateTimeToString(date);
}

export const dateToDisplayShort = (date) => {
    return ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + "." +
        ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + "." +
        date.getFullYear().toString().substring(2, 4);
}

export const jsonDateToMailDisplay = (jsonDate) => {
    var date = new Date(jsonDate)
    if (isToday(date)) {
        return (date.getHours() > 9 ? date.getHours() : '0' + date.getHours()) + ":" +
            (date.getMinutes() > 9 ? date.getMinutes() : '0' + date.getMinutes());
    };

    return wochentag[date.getDay()] + " " +
        ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + "." +
        ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + ".";
}

export const jsonDateToChatDisplay = (jsonDate) => {
    var date = new Date(jsonDate)
    if (isToday(date)) {
        return (date.getHours() > 9 ? date.getHours() : '0' + date.getHours()) + ":" +
            (date.getMinutes() > 9 ? date.getMinutes() : '0' + date.getMinutes()) + ":" +
            (date.getSeconds() > 9 ? date.getSeconds() : '0' + date.getSeconds());
    };

    return wochentag[date.getDay()] + " " +
        ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + "." +
        ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + ".";
}

export const jsonDateTimeToDisplay = (jsonDate) => {
    var date = new Date(jsonDate)
    return ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + "." +
        ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + "." +
        date.getFullYear() + " " +
        (date.getHours() > 9 ? date.getHours() : '0' + date.getHours()) + ":" +
        (date.getMinutes() > 9 ? date.getMinutes() : '0' + date.getMinutes());
}

export const isToday = (someDate) => {
    const today = new Date()

    return someDate.getDate() == today.getDate() &&
        someDate.getMonth() == today.getMonth() &&
        someDate.getFullYear() == today.getFullYear()
}

export const olderThanDays = (someDate, days) => {
    var date = new Date(someDate)

    const today = new Date();
    date.setDate(date.getDate() + days);

    return (date.getTime() < today.getTime());
}

const wochentag = ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'];