import React from 'react';
import { connect } from 'react-redux'

import Alert from '@material-ui/lab/Alert';
import FptForceAnmeldung from '../../utils/FptForceAnmeldung';
import FptHeader from '../../utils/FptHeader';
import FptWerWann from '../generic/FptWerWann';
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography';

import { config } from '../../../config/config';
import MeineAnwesenheiten from '../generic/MeineAnwesenheiten';
import FptLegende from '../../utils/FptLegende';
import { Link } from 'react-router-dom';
import { AlertTitle } from '@material-ui/lab';

const idFpt = 'fpt035';
const titel = 'Fachpressetage 2024';

const PageWerWann = (props) => {



	const isJc = () => {
		/// Achtungt das kann empty sein, wenn das kein redakteur ist!
		if (!props.teilnehmerRedakteur) { return false }

		const tln = props.teilnehmerRedakteur.find(red => red.idAuftragFpt.includes(idFpt));
		if (tln.idFptTeilnehmerTyp.includes('jc')) { return true }

		return false;
	}

	return (
		<React.Fragment>
			<FptHeader title={titel} />

			<FptForceAnmeldung idFpt={idFpt} fptTitel={titel}>

				{isJc() ?
					<Grid container spacing={2}>
						<Grid item xs={12} md={12} lg={8} style={{ marginBottom: '16px' }}>
							<Typography variant="h4" gutterBottom>
								Online-Teilnehmer
							</Typography>


							<Typography variant="body1" gutterBottom>
								Sie sind als Online-Teilnehmer angemeldet. Daher spielen physische Anwesenheits-Daten für Sie keine Rolle.
								Bitte nehmen Sie ggf. per Mail Kontakt mit <Link to={'/' + idFpt + '/teilnehmer'}>Teilnehmern</Link> auf.
							</Typography>
						</Grid>
					</Grid>
					:
					<React.Fragment>
						<Grid container spacing={2}>
							<Grid item xs={12} md={12} lg={8} style={{ marginBottom: '16px' }}>
								<Typography variant="h4" gutterBottom>
									Meine Daten
								</Typography>

								{config[idFpt].anwesenheitenEintragen ?
									<Typography variant="body1" gutterBottom>
										Bitte geben Sie an, zu welchen Programmteilen Sie anwesend sein werden. Dann können andere Teilnehmer
										Gespräche mit Ihnen planen. Sie sind natürlich herzlich zu allen Vorträgen eingeladen.
									</Typography>
									:
									<Alert severity="info">
										<AlertTitle>Sorry, zu spät!</AlertTitle>
										Unsere Planung ist abgeschlossen, Sie können hier keine Daten mehr ändern. Wenden Sie sich ggf. 
										an <a href='mailto:ioehlschlaeger@rbsonline.de'>ioehlschlaeger@rbsonline.de</a>
									</Alert>
								}
								{/* 
								<Typography variant="body1" gutterBottom>
									Hier finden Sie das <a href="https://fachpressetage.rbsonline.de/fachpressetage-2023.php" target="_blank">Programm</a>.
								</Typography>
								*/}

								<MeineAnwesenheiten disabled={!config[idFpt].anwesenheitenEintragen} idFpt={idFpt} />
							</Grid>

							<Grid item xs={12}>
								<FptLegende idFpt={idFpt} />
							</Grid>

							{config[idFpt].anwesenheitenAnzeigen &&
								<Grid item xs={12} md={12} lg={8}>
									<Typography variant="h4" gutterBottom>
										Alle Teilnehmer
									</Typography>

									{config[idFpt].meetingsOpen &&
										<Alert severity="info" style={{ marginBottom: '24px' }}>Klicken Sie auf eine Person, um sie zu einem Gespräch einzuladen.</Alert>
									}

									<FptWerWann idFpt={idFpt} />

								</Grid>
							}

						</Grid>
					</React.Fragment>
				}

			</FptForceAnmeldung>

		</React.Fragment>
	);

}


const mapStateToProps = state => ({
	teilnehmerRedakteur: state.global.teilnehmerRedakteur,
	//allePersonen: state.fpt[idFpt].allePersonen
});

export default connect(mapStateToProps)(PageWerWann);
