import React, { useState, useEffect } from 'react';


import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import FptLink from './FptLink';

const EditableField = (props) => {

    useEffect(() => {
        if (props.isBool) {
            setBoolValue(props.value);
        }
        else {
            setFieldValue(props.value ? props.value : '');
        }
    }, [props.value]);

    const [fieldValue, setFieldValue] = useState('');
    const [boolValue, setBoolValue] = useState(false);

    const txtChanged = (e) => {
        setFieldValue(e.target.value)
    }

    const boolChanged = (e) => {
        console.log(e.target.checked);
        props.onChange && props.onChange(props.name, e.target.checked);
        setBoolValue(e.target.checked);
    }

    const txtBlur = (e) => {
        props.onChange && props.onChange(props.name, fieldValue);
    }

    const getDisplayValue = (value) => {
        if (props.displayMaxSize) {
            return value.length > props.displayMaxSize ? value.substring(0, props.displayMaxSize) + "..." : value;
        }
        else {
            return value;
        }

    }

    const getLink = (link, fieldValue) => {
        switch (link) {
            case "web":
                return fieldValue
                break;
            case "mailto:":
                return "mailto:" + fieldValue;
                break;
            case "xing":
                if (fieldValue.startsWith('http')) {
                    return fieldValue;
                } else {
                    return "https://www.xing.com/profile/" + fieldValue;
                }
                break;
            case "linkedIn":
                if (fieldValue.startsWith('http')) {
                    return fieldValue;
                } else {
                    return "https://www.linkedin.com/in/" + fieldValue;
                }
                break;
            default:
            // code block
        }
    }

    return (
        <React.Fragment>
            {props.isBool &&
                <Checkbox
                    disabled={!props.isEdit}
                    checked={boolValue}
                    label={props.label}
                    size="small"
                    onChange={boolChanged}
                />
            }

            {!props.isEdit && !props.isBool &&
                <Typography variant="body2" gutterBottom>
                    {props.link ?
                        <FptLink
                            typoVariant="body2"
                            href={getLink(props.link, fieldValue)}
                        >
                            {fieldValue ? getDisplayValue(fieldValue) : <span>&nbsp;</span>}
                        </FptLink>
                        :
                        <React.Fragment>
                            {fieldValue ? getDisplayValue(fieldValue) : <span>&nbsp;</span>}
                        </React.Fragment>
                    }

                </Typography>
            }

            {props.isEdit && !props.isBool &&
                <TextField
                    autoFocus={false}
                    fullWidth
                    value={fieldValue}
                    label={props.label}
                    variant="outlined"
                    size="small"
                    onChange={txtChanged}
                    onBlur={txtBlur}
                    multiline={props.multiline}
                    rowsMax={props.rowsMax ? props.rowsMax : 4}
                />
            }
        </React.Fragment>
    )
}

export default EditableField;