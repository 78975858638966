import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import FptCard from '../../utils/FptCard';
import FptLink from '../../utils/FptLink';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

import { startReadProfileFirmaByUser, startTeilnahmeRedakteurAnmelden } from '../../../actions/fpt';
import { setGlobalSuccess } from '../../../actions/global';
import { config } from '../../../config/config';
import { Checkbox } from '@material-ui/core';

const currentFpt = config.currentFpt;

const FormAnmelden = (props) => {

    const history = useHistory();

    useEffect(() => {
        props.dispatch(startReadProfileFirmaByUser(props.user.idFptProfilPerson, props.idFpt));
    }, []);

    const [tln, setTln] = useState('ja');
    const [trotzdemPortal, setTrotzdemPortal] = useState(false);

    const anmelden = () => {
        var status = tln;
        if ((status === 'jn') && trotzdemPortal) {
            status = 'jc';
        }

        props.dispatch(startTeilnahmeRedakteurAnmelden(props.user, currentFpt, status, onRedakteurAngemeldet));
    }

    const onRedakteurAngemeldet = () => {
        tln === 'ja' && props.dispatch(setGlobalSuccess("Erfolgriech angemeldet!"))
        tln === 'ja' && history.push(`/${currentFpt}/werWann/`)
    }

    const bttnAnmeldenClick = () => {
        anmelden();
    }


    const isJournalist = props.user && props.user.idFptTeilnehmerTyp.includes('j');

    return (
        <div>
            <Alert style={{ marginBottom: '14px' }} severity="error">
                Sie sind nicht für die Veranstaltung {props.fptTitel} angemeldet.
                {props.idFpt !== currentFpt && <div>Hinweis: Für vergangene Veranstaltungen ist leider keine Anmeldung mehr möglich.</div>}
            </Alert>

            <Grid container spacing={0}>
                <Grid item xs={12}>

                    {props.idFpt == currentFpt && isJournalist && !config[props.idFpt].anmeldungJ &&
                        <React.Fragment>
                            <Typography variant="h5" gutterBottom>
                                Hinweis
                            </Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={8} lg={6}>
                                    <Typography variant="body1" gutterBottom>
                                       Die Anmeldemöglichkeit für Journalisten schalten wir frei, sobald das Vortrags-Programm steht.
                                    </Typography>
                                </Grid>
                            </Grid>

                        </React.Fragment>
                    }

                    {props.idFpt == currentFpt && isJournalist && !!config[props.idFpt].anmeldungJ &&
                        <React.Fragment>
                            <Typography variant="h4" gutterBottom>
                                Anmeldung
                            </Typography>

                            <Grid container spacing={2}>
                                <Grid item xs={12} md={8} lg={6}>
                                    <Typography variant="body1" gutterBottom>
                                        Die nächsten Fachpressetage finden <strong>am {config[config.currentFpt].termin} in Karlsruhe</strong> statt.
                                        <ul>
                                            {/* 
                                            <li>
                                                Das <a href="https://fachpressetage.rbsonline.de/Fachpressetage2024" target="_blank">Vortrags-Programm finden Sie hier</a>
                                                &nbsp;(öffnet neues Fenster).
                                            </li>*/}
                                            <li>Sie erhalten automatisch auch Zugang zu allen Pressetexten und zusätzliche Kontaktmöglichkeiten über dieses Portal.</li>
                                            <li>Alle wichtigen Infos finden Sie auf 
                                                unserer <a target="_blank" href="https://fachpressetage.rbsonline.de/FAQ?typ=red">FAQ-Seite</a>.</li>
                                        </ul>
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container spacing={2}>
                                <Grid item xs={12} md={8} lg={6}>
                                    <FptCard
                                        title={'Anmeldung ' + props.fptTitel}
                                        buttons={<Button variant="contained" color="primary" onClick={bttnAnmeldenClick}>anmelden</Button>}
                                    >
                                        <Typography variant="body1" gutterBottom>
                                            Hiermit melde ich mich zu den Fachpressetagen an. Ich vertrete folgende Redaktionen:
                                            <ul>
                                                {props.myFirmenProfile && props.myFirmenProfile.map(profil =>
                                                    <li key={profil.idFptProfilFirma}>{profil.firmenname}</li>
                                                )}
                                            </ul>
                                        </Typography>

                                        <FormControl component="fieldset">
                                            <RadioGroup name="teilnahme" value={tln} onChange={(e) => setTln(e.target.value)}>
                                                <FormControlLabel value="ja" control={<Radio />} label="Ja, ich möchte vor Ort teilnehmen." />
                                                <FormControlLabel value="jc" control={<Radio />} label="Ich kann leider nicht kommen, möchte aber den Livestream verfolgen." />
                                                <FormControlLabel value="jn" control={<Radio />} label="Nein, ich werde nicht teilnehmen." />
                                            </RadioGroup>
                                            { /* 
                                            <FormControlLabel
                                                style={{ marginLeft: '24px' }}
                                                value="jc"
                                                control={
                                                    <Checkbox
                                                        checked={trotzdemPortal}
                                                        onChange={(e) => setTrotzdemPortal(e.target.checked)}
                                                        disabled={tln == "ja"}
                                                    />
                                                }
                                                label="Ich möchte trotzdem Zugriff auf die Beiträge erhalten." />
                                                */}
                                        </FormControl>
                                    </FptCard>

                                    <Typography variant="body1" gutterBottom>
                                        Hinweis: Ihre Anmeldung wird manuell gepfüft, daher bitte wir um etwas Geduld,
                                        bis Sie die Bestätigung erhalten.
                                    </Typography>

                                </Grid>
                            </Grid>
                        </React.Fragment>
                    }

                    {props.idFpt === "fpt032" && !props.user.idFptTeilnehmerTyp.includes('j') &&
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <Typography variant="body1" gutterBottom>
                                    Wenn Sie mit als Firmenteilnehmer dabei sein möchten, können Sie hier eine Teilnahmen buchen:
                                    <FptLink href="https://fachpressetage.rbsonline.de/anmeldung.php">Anmdelung & Konditionen</FptLink>
                                </Typography>
                                <br />
                                <Typography variant="body1" gutterBottom>
                                    Sie haben eine Teilname gebucht, erhalten aber dennoch diese Meldung? Dann ist uns leider ein Fehler unterlaufen.
                                    Bitte melden Sie sich bei uns: +49 7244 73969-0 oder kontakt@rbsonline.de
                                </Typography>
                            </Grid>
                        </Grid>
                    }

                </Grid>
            </Grid>


        </div>
    )

}

const mapStateToProps = state => ({
    user: state.global.user,
    myFirmenProfile: state.fpt[currentFpt].myFirmenProfile
});

export default connect(mapStateToProps)(FormAnmelden);