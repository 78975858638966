import React, { Fragment } from 'react';
import { connect } from 'react-redux'

import FptCard from './FptCard';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import FptLink from './FptLink';
import ReactHtmlParser from 'react-html-parser';
import { Chip } from '@material-ui/core';


/**
 * Zeigt eine Themenseite an
 * 
 * Der User 1976 (Alex) darf immer Download
 * 
 * 
 * @param {object} props Component props
 * @param {object} props.thema (required)
 */
const FptThemaCard = (props) => {

    var md5 = require('md5');

    const calculateHash = () => {
        const originalString = "" + props.thema.idFptProfilFirma + props.thema.idFptVeranstaltung + "FPT-UPLOAD";
        const hash = md5(originalString);
        return hash.toUpperCase();
    }

    return (
        <React.Fragment>
            {props.thema &&

                <FptCard
                    title={props.thema.titel}
                    buttons={
                        !props.disableDownload &&
                        <Fragment>
                            {(props.idPersonUser === 1976) || props.download ?
                                <form method="post" action="https://fptfiles.rbsonline.de/portal/download.php" target="_blank">
                                    <input type="hidden" name="id" value={props.thema.idFptProfilFirma} />
                                    <input type="hidden" name="idVeranstaltung" value={props.thema.idFptVeranstaltung} />
                                    <input type="hidden" name="idThema" value={props.thema.idFptThema} />
                                    <input type="hidden" name="titel" value={props.thema.titel} />
                                    <input type="hidden" name="h" value={calculateHash()} />

                                    <Button variant="contained" color="primary" type="submit">Download</Button>
                                </form>
                                :
                                <Button variant="contained" disabled color="primary">Download</Button>
                            }
                        </Fragment>
                    }>
                        
                    <div style={{ position: 'relative' }}>
                        {props.thema.idFptVeranstaltungsblock < 5 &&
                            <Chip
                                style={{ position: 'absolute', right: '0px' }}
                                size="small"
                                label={props.thema.idFptVeranstaltungsblock === 4 ? "Fachvortrag" : props.thema.idFptVeranstaltungsblock === 3 && "Gesprächs-Tisch"}
                                color="secondary"
                            />
                        }
                        {props.thema.idFptVeranstaltungsblock === 4 &&
                            <Typography variant="h6" gutterBottom>
                                {props.thema && props.thema.personReferent2 ? 'Referenten: ' : 'Referent: '}
                                {props.thema && props.thema.titelReferent} {props.thema && props.thema.vornameReferent} {props.thema && props.thema.nachnameReferent}
                                {props.thema && props.thema.personReferent2 && ', ' + props.thema.personReferent2}
                            </Typography>
                        }

                        <Typography variant="body2" gutterBottom>
                            {ReactHtmlParser(props.thema.abstract)}
                        </Typography>

                        {props.thema.link &&
                            <Typography variant="h6" gutterBottom>
                                Weitere Infos: <FptLink component="span" href={props.thema.link}>{props.thema.link}</FptLink>
                            </Typography>
                        }

                    </div>
                </FptCard >
            }
        </React.Fragment >
    )

}



const mapStateToProps = state => ({
    idPersonUser: state.global.user.idFptProfilPerson
});

export default connect(mapStateToProps)(FptThemaCard);
