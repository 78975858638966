import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

import Button from '@material-ui/core/Button';
import EditableField from './EditableField'
import FptCard from './FptCard';
import MenuItem from '@material-ui/core/MenuItem';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';

import { startUpdateThema } from '../../actions/fpt';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';


const FptThemaEditCard = (props) => {

    var md5 = require('md5');
    const initFormData = {}

    const [touched, setTouched] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [formData, setFormData] = useState(props.thema);

    const bttnSaveClick = () => {
        setIsEdit(false);
        if (touched) {
            const saveData = {
                ...formData,
                idPersonReferent: formData.idPersonReferent === -1 ? null : formData.idPersonReferent
            }
            props.dispatch(startUpdateThema(props.idFpt, saveData));
        }
    }

    const bttnBearbeitenClick = () => {
        setIsEdit(true);
    }

    const handleOnlineChange = (event, newValue) => {
        txtFieldChange("online", newValue);
    };

    const txtFieldChange = (key, value) => {
        setFormData({
            ...formData,
            [key]: value
        });
        setTouched(true);
    }

    const selReferentChanged = (event, child) => {
        setTouched(true);
        const person = child.props.data ? child.props.data : { vorname: 'N.', nachname: 'N.' };

        // Achtung: Wir müssen auch Vor- und Nachname schreiben für die nicht-Editieren Ansicht.
        setFormData({
            ...formData,
            idPersonReferent: event.target.value,
            titelReferent: person.titel,
            vornameReferent: person.vorname,
            nachnameReferent: person.nachname
        })
    }

    const calculateHash = () => {
        const originalString = "" + props.thema.idFptProfilFirma + props.thema.idFptVeranstaltung + "FPT-UPLOAD";
        const hash = md5(originalString);
        return hash.toUpperCase();
    }

    return (
        <FptCard
            style={{ marginBottom: '16px' }}
            title={formData.veranstaltung}
            buttons={
                <React.Fragment>
                    {isEdit ?
                        <Button color='primary' onClick={bttnSaveClick}>Speichern</Button>
                        :
                        <Button color='secondary' onClick={bttnBearbeitenClick}>Bearbeiten</Button>
                    }
                    {!props.hideUpload &&
                        <form method="get" action="https://fptfiles.rbsonline.de/portal/uploadtool.php" target="_blank">
                            <input type="hidden" name="id" value={formData.idFptProfilFirma} />
                            <input type="hidden" name="idVeranstaltung" value={formData.idFptVeranstaltung} />
                            <input type="hidden" name="idThema" value={formData.idFptThema} />
                            <input type="hidden" name="titel" value={formData.titel} />
                            <input type="hidden" name="h" value={calculateHash()} />

                            <Button color="secondary" type="submit">Dateien hochladen</Button>
                        </form>
                    }
                </React.Fragment>
            }
        >
            <Table size={isEdit && "small"}>
                <TableBody>
                    {formData.idFptVeranstaltungsblock == 4 && 1 == 2 && (
                        <TableRow>
                            <TableCell width='150px' component="th" scope="row">
                                digital/vor Ort:
                            </TableCell>
                            <TableCell >
                                <ToggleButtonGroup
                                    color="primary"
                                    value={formData.online}
                                    exclusive
                                    onChange={handleOnlineChange}
                                    aria-label="Platform"
                                >
                                    <ToggleButton disabled={!isEdit} value={true}>digitaler Vortrag</ToggleButton>
                                    <ToggleButton disabled={!isEdit} value={false}>Vortrag vor Ort</ToggleButton>
                                </ToggleButtonGroup>
                            </TableCell>
                        </TableRow>
                    )}
                    <TableRow>
                        <TableCell width='150px' component="th" scope="row">
                            Titel:
                        </TableCell>
                        <TableCell >
                            <EditableField
                                value={formData.titel}
                                name="titel"
                                isEdit={isEdit}
                                onChange={txtFieldChange}
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell component="th" scope="row">
                            Abstract:
                        </TableCell>
                        <TableCell >
                            <EditableField
                                value={formData.abstract}
                                name="abstract"
                                isEdit={isEdit}
                                multiline={true}
                                rowsMax={8}
                                onChange={txtFieldChange}
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell component="th" scope="row">
                            Referent:
                        </TableCell>
                        <TableCell >
                            {isEdit ?
                                <React.Fragment>
                                    <Select
                                        value={formData.idPersonReferent ? formData.idPersonReferent : -1}
                                        onChange={selReferentChanged}
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                    >
                                        <MenuItem value={-1}>N. N.</MenuItem>
                                        {props.fpt[props.idFpt].firmen[formData.idFptProfilFirma].teilnehmerPersonen.map(person =>
                                            <MenuItem
                                                value={person.idFptProfilPerson}
                                                key={person.idFptProfilPerson}
                                                data={person}
                                            >
                                                {person.titel ? person.titel : ''} {person.vorname} {person.nachname}
                                            </MenuItem>
                                        )}
                                    </Select>
                                    <Typography variant="body2" gutterBottom>
                                        Der Referent fehlt in der Auswahlliste? Bitte melden Sie ihn
                                        per <a href="mailto:ioehlschlaeger@rbsonline.de?subject=Fachpressetage%20-%20Referent%20anmelden">Mail</a> als Teilnehmer an.
                                    </Typography>

                                </React.Fragment>
                                :
                                <Typography variant="body2" gutterBottom>
                                    {(formData.titelReferent ? formData.titelReferent + " " : "") +
                                        (formData.vornameReferent ? formData.vornameReferent : "N.") + " " +
                                        (formData.nachnameReferent ? formData.nachnameReferent : 'N.')}
                                </Typography>
                            }
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell component="th" scope="row">
                            Link:
                        </TableCell>
                        <TableCell >
                            <EditableField
                                value={formData.link}
                                name="link"
                                isEdit={isEdit}
                                onChange={txtFieldChange}
                            />
                        </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell component="th" scope="row">
                            Grundlagen:
                        </TableCell>
                        <TableCell >
                            <EditableField
                                isBool={true}
                                value={formData.grundlagen}
                                name="grundlagen"
                                isEdit={isEdit}
                                onChange={txtFieldChange}
                            />
                        </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell component="th" scope="row">
                            Weltneuheit:
                        </TableCell>
                        <TableCell >
                            <EditableField
                                isBool={true}
                                value={formData.weltneuheit}
                                name="weltneuheit"
                                isEdit={isEdit}
                                onChange={txtFieldChange}
                            />
                        </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell component="th" scope="row">
                            Neuentwicklung:
                        </TableCell>
                        <TableCell >
                            <EditableField
                                isBool={true}
                                value={formData.neuentwicklung}
                                name="neuentwicklung"
                                isEdit={isEdit}
                                onChange={txtFieldChange}
                            />
                        </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell component="th" scope="row">
                            Anwendungsbsp.:
                        </TableCell>
                        <TableCell >
                            <EditableField
                                isBool={true}
                                value={formData.anwendungsbeispiel}
                                name="anwendungsbeispiel"
                                isEdit={isEdit}
                                onChange={txtFieldChange}
                            />
                        </TableCell>
                    </TableRow>

                </TableBody>
            </Table>

        </FptCard>
    )

}

const mapStateToProps = state => ({
    user: state.global.user,
    fpt: state.fpt,
});

export default connect(mapStateToProps)(FptThemaEditCard);

