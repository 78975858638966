import React, { useEffect } from 'react';
import { connect } from 'react-redux'

import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import FormAnmelden from '../fpt/generic/FormAnmelden';

import { config } from '../../config/config';
import { startTeilnahmeRedakteurAnmelden } from '../../actions/fpt';
import { olderThanDays } from '../../helper/dateHelper';


const FptForceAnmeldung = (props) => {

    console.log('FptForceAnmeldung', props);

    const isAngemledet = (idFpt) => {

        if (!!props.bypass) {
            return true;
        }

        if (props.user.idFptTeilnehmerTyp.includes('j')) {
            if (props.ablaufdatum) {
                if (!olderThanDays(props.ablaufdatum, 0)) {
                    return true;
                }
            }

            if (props.teilnehmerRedakteur) {
                return props.teilnehmerRedakteur.some(tln => tln.idAuftragFpt.includes(idFpt) &&
                    (tln.idFptTeilnehmerTyp.includes('ja') || tln.idFptTeilnehmerTyp.includes('jc')));
            }
        }

        if (props.user.idFptTeilnehmerTyp.includes('f')) {
            if (props.teilnehmerFirma) {
                return props.teilnehmerFirma.some(tln => tln.idAuftragFpt.includes(idFpt));
            }
        }

        return false;
    }

    const isAbgemledet = (idFpt) => {        
        if (props.user.idFptTeilnehmerTyp.includes('j')) {
            if (props.teilnehmerRedakteur) {
                return props.teilnehmerRedakteur.some(tln => tln.idAuftragFpt.includes(idFpt) && tln.idFptTeilnehmerTyp.includes('jn'));
            }
        }

        return false;
    }


    const bttnResetAnmeldung = () => {
        props.dispatch(startTeilnahmeRedakteurAnmelden(props.user, props.idFpt, 'j'));
    }

    return (
        <React.Fragment>
            {isAngemledet(props.idFpt) ?
                <React.Fragment>
                    {props.children}
                </React.Fragment>
                :
                isAbgemledet(props.idFpt) ?
                    <div>
                        <Alert style={{ marginBottom: '14px' }} severity="error">
                            Leider haben Sie sich für die Veranstaltung {props.fptTitel} abgemeldet.<br />
                            Sie haben Ihre Meinung geändert? Dann können Sie sich erneut anmelden:<br />
                            <Button onClick={bttnResetAnmeldung}>zur Anmeldung</Button>
                        </Alert>
                    </div>
                    :
                    <FormAnmelden fptTitel={props.fptTitel} idFpt={props.idFpt} />

            }
        </React.Fragment>
    );

}


const mapStateToProps = state => ({
    user: state.global.user,
    teilnehmerRedakteur: state.global.teilnehmerRedakteur,
    teilnehmerFirma: state.global.teilnehmerFirma,
});

export default connect(mapStateToProps)(FptForceAnmeldung);