import React, { useEffect } from 'react';
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

import PeopleIcon from '@material-ui/icons/People';
import { FptList, FptListItem } from '../../utils/FptList';

import { startGetRedakteureTitel } from '../../../actions/fpt';
import { logoUri } from '../../../config/globals';


const FptRedaktionen = (props) => {

    const history = useHistory();

    useEffect(() => {
        // load Data
        loadRedaktionen();
    }, []);


    const loadRedaktionen = () => {
        props.dispatch(startGetRedakteureTitel(props.idFpt, true));
    }

    const einladen = (idPerson) => {
        history.push(`/${props.idFpt}/einladen/${idPerson}/${props.idFpt}/true/false`);
    }

    const profil = (idPerson) => {
        history.push(`/${props.idFpt}/profil/${idPerson}`);
    }


    return (
        <React.Fragment>
            <FptList>

                {props.fpt[props.idFpt].redakteureTitel && props.fpt[props.idFpt].redakteureTitel
                    .sort((a, b) => (a.firmenname.toUpperCase() < b.firmenname.toUpperCase() ? -1 : 1))
                    .map(red =>
                        <FptListItem
                            key={red.idFptTeilnehmerRedakteurFirma}
                            primaryText={red.firmenname}
                            secondaryText={(red.titel ? red.titel + " " : "") + " " + red.vorname + " " + red.nachname}
                            onClick={() => profil(red.idFptProfilPerson)}
                            image={red.logoFile ? logoUri + red.logoFile : 'none'}
                            iconRechts={'people'}
                            tooltipRechts={(red.titel ? red.titel + " " : "") + " " + red.vorname + " " + red.nachname + " zum Gespräch einladen."}
                            onClickRechts={() => einladen(red.idFptProfilPerson)}
                            chipRechts={red.idFptTeilnehmerTyp.includes('jc') && 'online-Teilnehmer'}
                        />
                    )
                }

            </FptList>
        </React.Fragment>
    );


    //image={thema.logoFile && logoUri + thema.logoFile}
}


const mapStateToProps = state => ({
    fpt: state.fpt,
});

export default connect(mapStateToProps)(FptRedaktionen);